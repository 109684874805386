import { useTranslation } from "react-i18next";
import React, { useState } from "react";
const GetUserStatus = () => {
  const { t } = useTranslation();
  return [
    { value: 1, label: t("active") },
    { value: 2, label: t("deactive") },
  ];
};

export default GetUserStatus;
