import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Flag from "react-world-flags";

// Danh sách ngôn ngữ
const languageOptions = [
  { value: "vi", label: "Tiếng Việt", code: "VN" },
  { value: "zh", label: "中文", code: "CN" },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const currentLanguage =
    languageOptions.find((opt) => opt.value === i18n.language) ||
    languageOptions[0];

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang.value);
  };
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="mt-3 text-center"
    >
      <DropdownToggle caret color="primary">
        {"Language "}
        <Flag code={currentLanguage.code} style={{ width: 20, height: 15 }} />
      </DropdownToggle>
      <DropdownMenu>
        {languageOptions.map((lang) => (
          <DropdownItem key={lang.value} onClick={() => changeLanguage(lang)}>
            <Flag
              code={lang.code}
              style={{ width: 20, height: 15, marginRight: 10 }}
            />
            {lang.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelector;
