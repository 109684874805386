import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  FormFeedback,
  Badge,
} from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const url = process.env.REACT_APP_API_URL;
const publicUrl = process.env.REACT_APP_API_PUBLIC_URL;

const CheckDomain = () => {
  const { t, i18n } = useTranslation();
  document.title = "Check Domain";
  const [domain, setDomain] = useState("");
  const [deviceType, setDeviceType] = useState("PC");
  const [loading, setLoading] = useState(false);
  const [checkButton, setCheckButton] = useState(t("check"));
  const [errors, setErrors] = useState({ domain: "", deviceType: "" });
  const [resultProxy, setResultProxy] = useState([]);

  const validateForm = () => {
    let newErrors = { domain: "", deviceType: "" };
    let isValid = true;

    if (!domain.trim()) {
      newErrors.domain = t("enterDomain");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const getBadgeColor = (responseCode) => {
    if (!responseCode) return "secondary"; // Không có mã HTTP → Màu xám

    if (responseCode >= 200 && responseCode < 300) return "success"; // ✅ 2xx (Thành công) → Xanh lá
    if (responseCode >= 300 && responseCode < 400) return "primary"; // 🔵 3xx (Chuyển hướng) → Xanh dương
    if (responseCode === 400) return "dark"; // ⚠️ 400 (Bad Request) → Đen
    if (responseCode === 403) return "danger"; // ❌ 403 (Forbidden) → Đỏ
    if (responseCode === 404) return "warning"; // 🟡 404 (Không tìm thấy) → Vàng
    if (responseCode >= 500) return "info"; // 🔵 5xx (Lỗi server) → Xanh dương nhạt

    return "secondary"; // Mặc định → Xám
  };
  const checkData = async () => {
    if (loading) return;
    if (!validateForm()) return;

    setLoading(true);
    setCheckButton(t("checkingProxy"));
    try {
      // Gọi API đầu tiên
      const res1 = await axios.get(`${url}api/tool/checkProxy`, {
        params: { lang: document.documentElement.lang },
      });

      if (res1.success) {
        toast.success(`${t("success")}. ${t("checkingDomain")}`, {
          position: "top-right",
        });
        setCheckButton(t("checkingDomain"));
        setResultProxy(res1.data || []);
        const res2 = await axios.post(
          `${url}api/tool/checkDomain?lang=${document.documentElement.lang}`,
          {
            domain,
            deviceType,
            resultProxy: res1.data,
          }
        );

        if (res2.success) {
          setResultProxy(res2.data || []);
        }
      }
    } catch (error) {
      toast.error(`${t("failed")}`, {
        position: "top-right",
      });
    }
    setLoading(false);
    setCheckButton(t("check"));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("checkDomain")} pageTitle={t("Domain")} />
          <Row>
            <Col>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  checkData();
                }}
              >
                <FormGroup>
                  <legend> {t("domain")}</legend>
                  <Input
                    name="domain"
                    placeholder="https://example.com"
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    invalid={!!errors.domain}
                  />
                  <FormFeedback>{errors.domain}</FormFeedback>
                </FormGroup>

                <FormGroup tag="fieldset">
                  <legend> {t("deviceEmulation")}</legend>
                  <FormGroup check>
                    <Input
                      type="radio"
                      value="PC"
                      checked={deviceType === "PC"}
                      onChange={(e) => setDeviceType(e.target.value)}
                    />
                    <Label check>PC</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="radio"
                      value="MOBILE"
                      checked={deviceType === "MOBILE"}
                      onChange={(e) => setDeviceType(e.target.value)}
                    />
                    <Label check>MOBILE</Label>
                  </FormGroup>
                </FormGroup>

                <Button color="primary" disabled={loading} onClick={checkData}>
                  {loading && <Spinner size="sm" className="me-2" />}{" "}
                  {checkButton}
                </Button>
              </Form>
            </Col>
          </Row>

          {/* Kết quả kiểm tra */}
          {resultProxy.length > 0 && (
            <Row className="mt-4">
              {resultProxy.map((item, index) => (
                <Col key={index} md={6} lg={3} className="mb-3">
                  <Card className="border rounded-3 shadow-sm bg-light">
                    <CardBody>
                      <CardTitle tag="h5" className="fw-bold text-primary">
                        {item.network}{" "}
                        {item.deviceType && `(${item.deviceType})`}
                      </CardTitle>
                      <CardText>
                        <strong>REDIRECT TO:</strong>{" "}
                        <a
                          href={item.redirectTo}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.redirectTo}
                        </a>
                      </CardText>
                      <CardText>
                        <strong>PROXY INFO:</strong> {item.proxy || "N/A"}
                      </CardText>
                      <CardText>
                        <strong>PROXY IP:</strong> {item.proxyIp || "N/A"}
                      </CardText>
                      <CardText>
                        <strong>STATUS:</strong>{" "}
                        {item.status?.map((status) => status.code).join(", ")}
                      </CardText>
                      {item.status?.map((status, index) => (
                        <Badge
                          key={index}
                          color={getBadgeColor(status.code)}
                          className="p-2 fw-bold me-1 mb-2"
                        >
                          {status.text}
                        </Badge>
                      ))}
                      {item.domainScreenshot && (
                        <div className="mt-2">
                          <img
                            src={`${publicUrl}${item.domainScreenshot}`}
                            alt="Ảnh chụp màn hình"
                            style={{ maxWidth: "100%", height: "auto" }}
                            onError={(e) => (e.target.style.display = "none")}
                          />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CheckDomain;
