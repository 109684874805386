import React, { useEffect } from "react";
import "./locales/i18n";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <React.Fragment>
      <Route />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
