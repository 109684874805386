const resources = {
  vi: {
    translation: {
      login: "Đăng nhập",
      logout: "Đăng xuất",
      username: "Tên đăng nhập",
      fullname: "Tên người dùng",
      password: "Mật khẩu",
      enterUsername: "Vui lòng nhập đăng nhập",
      enterPassword: "Vui lòng nhập mật khẩu",
      tool: "Công cụ",
      checkDomain: "Kiểm tra tên miền",
      domain: "Tên miền",
      enterDomain: "Vui lòng nhập tên miền",
      checkIP: "Kiểm tra IP",
      users: "Người dùng",
      listUser: "Danh sách người dùng",
      deviceEmulation: "Giả lập thiết bị",
      check: "Kiểm tra",
      status: "Trạng thái",
      search: "Tìm kiếm",
      create: "Tạo mới",
      edit: "Chỉnh sửa",
      delete: "Xoá",
      confDelete: "Bạn có chắc chắn muốn thực hiện hành động xoá!",
      success: "Thành công",
      failed: "Thất bại",
      refreshPage: "Làm mới trang",
      createdAt: "Thời gian tạo",
      role: "Vai trò",
      action: "Hành động",
      active: "Kích hoạt",
      deactive: "Ngưng kích hoạt",
      checkingProxy: "Đang tiến hành kiểm tra proxy",
      checkingDomain: "Đang tiến hành kiểm tra Domain",
    },
  },
  zh: {
    translation: {
      login: "登录",
      logout: "退出登录",
      username: "用户名",
      fullname: "用户全名",
      password: "密码",
      enterUsername: "请输入用户名",
      enterPassword: "请输入密码",
      tool: "工具",
      checkDomain: "检查域名",
      domain: "域名",
      enterDomain: "请输入域名",
      checkIP: "检查IP",
      users: "用户",
      listUser: "用户列表",
      deviceEmulation: "设备仿真",
      check: "检查",
      status: "状态",
      search: "搜索",
      create: "创建",
      refreshPage: "刷新页面",
      createdAt: "创建时间",
      role: "角色",
      action: "操作",
      edit: "编辑",
      delete: "删除",
      confDelete: "您确定要执行删除操作吗？",
      success: "成功",
      failed: "失败",
      active: "启用",
      deactive: "停用",
      checkingProxy: "正在检查代理",
      checkingDomain: "正在检查域名",
    },
  },
};

export default resources;
